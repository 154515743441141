.contact-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* Add shadows */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="number"],
  input[type="text"],
  input[type="email"],
  input[type="company"],
  textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  
  .contact-form-submit-button {
    width: 100%;
    padding: 15px;
    background-color: var(--color-blue-primary);
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  /* .contact-form-submit-button:hover {
    background-color: #0056b3;
  } */
  