.footer__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem;
}
.footer__container ul li {
  margin: 1rem 0;
}
.footer__socials li {
  display: inline-block;
  margin-right: 1rem !important;
  padding: 2px;
}
.footer__socials li a {
  font-size: 1.25rem;
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .footer__container {
    grid-template-columns: 1fr;
  }
}

.contact-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  /* fit this container around parent */
  max-width: fit-content;
  margin: 0 auto;
}