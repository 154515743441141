.container__contact_us {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.portfolio__header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  padding-left: 3rem;
}
.contact-us-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
  padding: 0.5rem;
}
.portfolio__header button {
  width: fit-content;
  /* add outline to button */
  outline: none;
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .container__contact_us {
    grid-template-columns: 1fr;
  }
}
