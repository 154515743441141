.contact_us_footer {
  box-shadow: var(--box-shadow);
  border-radius: var(--radius);
  padding: 2rem;
  cursor: pointer;
  /* max-width: 60%; */
}
.contact_us_footer img {
  border-radius: var(--radius);
  object-fit: cover;
  display: block;
  width: 100%;
  height: 15rem;
}

.contact_us_footer h2 {
  margin: 0.5rem ;
}

.portfolio_button_container{
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.contact-us-heading{
  text-align: center;
}