.portfolio__work {
  box-shadow: var(--box-shadow);
  border-radius: var(--radius);
  padding: 1rem;
  cursor: pointer;
}
.portfolio__work img {
  border-radius: var(--radius);
  object-fit: cover;
  display: block;
  width: 100%;
  height: 15rem;
}

.portfolio__work h2 {
  margin: 0.5rem 0;
}

.portfolio_button_container{
  display: flex;
  justify-content: center;
  gap: 1rem;
}