.container__services {
}
.services__intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
  align-items: center;
}
.services__intro > div:last-of-type {
  width: 100%;
  position: relative;
}
.services__intro__image img {
  /* width: 400px;
  height: 490px; */
  width: 90%;
  object-fit: cover;
  border-radius: var(--radius);
  box-shadow: var(--box-shadow);
}

.services__intro__miniSlider {
  width: 10.4rem;
  border-radius: var(--radius);
  box-shadow: var(--box-shadow);
  padding: 1rem 1.4rem 2.4rem 1rem;
  position: absolute;
  top: 50%;
  left: 400px;
  transform: translate(-50%);
  background: white;
  z-index: 2;
}
.services__intro__miniSlider .icon {
  color: var(--color-blue-primary);
  font-size: 4.2rem;
}
.services__intro__miniSlider p {
  font-size: 0.8rem;
  line-height: 1.4;
  font-weight: 500;
}
.services__servicesComp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6.5rem;

  align-items: center;
  background: var(--linear-bg);
  border-radius: var(--radius);
  padding: 3rem 8rem;
  margin-top: 5rem;
}
.services__servicesComp h1 {
  margin-bottom: 3.5rem;
}
.services__servicesComp h4 {
  margin: 0.7rem 0;
}
.services__servicesComp p {
  font-size: 0.8rem;
  line-height: 1.4;
}
.services__servicesComp h1,
.services__servicesComp p,
.services__servicesComp h4 {
  color: white;
}
.services__servicesComp .icon {
  background: var(--color-light-gray);
  display: flex;
  width: min-content;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.services__servicesComp > div:last-of-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  .services__intro {
    grid-template-columns: 1fr;
    gap: 3.5rem;
  }
  .services__servicesComp {
    grid-template-columns: 1fr;
    padding: 1.5rem 4rem;
  }
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm) !important;
  }
  .services__intro__image {
    transform: scale(0.8);
  }
  .services__intro__miniSlider {
    transform: scale(0.8);
    left: 0;
  }
  .services__servicesComp {
    padding: 1rem 3rem;
  }
}
